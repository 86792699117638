import React, { useState, useContext, useEffect } from "react";
import ApiContext from "./../context/ApiContext";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown, faInfoCircle, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import fileDownload from "js-file-download";
import Spinner from "../components/Spinner";
import transImg from "./../images/transparent.png";
import Modal from "../components/Modal";

export default function Result({ location }) {

  const history = useHistory();

  const { apiData } = useContext(ApiContext);
  const [cardData, setCardData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const handleDownload = (url, filename) => {
    fetch(url).then((response) => {
      return response.blob().then((blob) => {
        fileDownload(blob, filename);
      });
    });
  };

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  useEffect(() => {
    if (apiData && !apiData.selectedCardUrl) {
      const params = queryString.parse(location.search);
      const baseUrl = apiData.baseUrl;
      const cardDataApi = apiData.cards && apiData.cards.filter((item) => {
        return item.id === parseFloat(params.card);
      })[0];

      if (cardDataApi) {
        setCardData({
          image: baseUrl + cardDataApi["Image"].formats.medium.url,
          title: cardDataApi["Title"],
          width: cardDataApi["Image"].formats.medium.width,
          height: cardDataApi["Image"].formats.medium.height,
        });
      }
      
      // No Card Param URL; Redirect to Home
      if (!(params && params.card)) {
        history.replace({
          pathname: "/",
          state: { isActive: true },
        });
      }
        
    }
  }, [apiData, location, history]);

  return (
    <div className="view">
      <div className="container mx-auto th-serif px-4">
        <h1 className="text-pink-700 text-3xl md:text-4xl mb-6 font-bold">
          ไพ่รุ้งของคุณ
        </h1>
        {cardData ? (
          <div className="card-result">
            <div
              className="flex items-center"
              style={{ perspective: "1000px" }}
            >
              <div className="relative mx-auto max-w-xs px-6 sm:px-4 mb-6">
                {/* Placeholder */}
                {!loaded && (
                  <div className="flex items-center justify-center">
                    <Spinner className="absolute z-10" />
                    <img
                      src={transImg}
                      style={{ width: cardData.width, height: "auto" }}
                      alt=""
                    />
                  </div>
                )}

                {/* Card Result */}
                <img
                  src={cardData.image}
                  className={`rounded-2xl shadow-lg anim-scale-up ${
                    loaded ? "block" : "hidden"
                  }`}
                  alt={`ไพ่รุ้ง - ${cardData.title}`}
                  onLoad={() => setLoaded(true)}
                />
              </div>
            </div>
            <div className="description">
              <p className="text-gray-700 text-xl lg:text-2xl">
                คุณได้ไพ่ <span className="font-bold">“{cardData.title}”</span>
              </p>
            </div>
          </div>
        ) : (
          <Spinner className="my-32" />
        )}

        <div className="flex flex-col sm:flex-row justify-between mt-6 xl:mt-8 mx-auto max-w-xl">
          <div className="mb-4 sm:mb-0">
            <button
              className="bg-rainbow transition text-white rounded-full sm:rounded-xl p-2 px-4 sm:px-3"
              onClick={() => {
                handleDownload(
                  cardData && cardData.image,
                  `ไพ่รุ้ง-${cardData.title}.png`
                );
              }}
            >
              <span className="inline sm:block mr-2 sm:mr-0">
                <FontAwesomeIcon icon={faArrowCircleDown} />
              </span>
              <span>ดาวน์โหลด</span>
            </button>
          </div>
          <div className="flex">
            <div className="flex flex-1 sm:block sm:w-auto border-r">
              <button
                className="w-full bg-white hover:bg-gray-50 transition p-2 px-3 rounded-l-xl text-pink-700 hover:text-pink-800"
                onClick={() => {
                  openModal();
                }}
              >
                <span className="block sm:mr-0 mr-2">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <span className="whitespace-nowrap">เกี่ยวกับไพ่รุ้ง</span>
              </button>
            </div>
            <div className="flex flex-1 sm:block sm:w-auto">
              <button
                className="w-full bg-white hover:bg-gray-50 transition p-2 px-3 rounded-r-xl text-pink-700 hover:text-pink-800"
                onClick={() => {
                  history.push("/");
                }}
              >
                <span className="block sm:mr-0 mr-2">
                  <FontAwesomeIcon icon={faRedoAlt} />
                </span>
                <span className="whitespace-nowrap">จับไพ่ใหม่</span>
              </button>
            </div>
            <Modal isOpen={isOpen} closeModal={closeModal} />
          </div>
        </div>
      </div>
    </div>
  );
}
