import React from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MainButton({ btnStyle, className, title, link, icon, iconPos, back }) {

  const history = useHistory();

  return (
    <button
      type="button"
      className={`${
        btnStyle === "primary" && `bg-rainbow text-white text-2xl p-3 px-7 rounded-full ${className}`
      } ${
        btnStyle === "secondary" && `text-pink-700 bg-white hover:bg-gray-50 hover:text-pink-800 transition p-2 px-4 rounded-full ${className}`
      }`}
      onClick={() => {
        if (back) {
          history.goBack();
        } else {
          history.push(link);
        }
      }}
    >
      <div className="mt-0.5">
        {icon && iconPos === "left" && (
          <span className="mr-2 pr-0.5">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
        <span>{title}</span>
        {icon && iconPos === "right" && (
          <span className="ml-2 pr-0.5">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </div>
    </button>
  );
}
