import React, { Fragment, useContext, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactMarkdown from "react-markdown";

import ApiContext from "./../context/ApiContext";

export default function Modal({ isOpen, closeModal }) {

  const { apiData } = useContext(ApiContext);
  const closeBtnRef = useRef(null);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={closeBtnRef}
        className="fixed inset-0 z-10 overflow-y-auto th-serif"
        onClose={closeModal}
      >
        {apiData && apiData.credit && (
          <div className="min-h-screen text-center p-4">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-pink-900 opacity-30" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="z-10 markdown-text inline-block w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h1"
                  className="text-3xl font-bold leading-tight text-pink-700"
                >
                  {apiData.credit.title}
                </Dialog.Title>
                <div className="mt-3 pb-1 border-b border-gray-300 border-dotted">
                  <ReactMarkdown>{apiData.credit.about_me}</ReactMarkdown>
                </div>

                <div className="mt-3 pb-3 border-b border-gray-300 border-dotted">
                  <ReactMarkdown>{apiData.credit.donate}</ReactMarkdown>
                </div>

                <div className="flex mt-3">
                  <div className="flex items-center mr-4">
                    <img
                      style={{ maxWidth: 90 }}
                      alt={apiData.credit.logo.alternativeText}
                      src={apiData.baseUrl + apiData.credit.logo.url}
                    />
                  </div>
                  <div className="flex flex-col justify-center text-sm">
                    <ReactMarkdown>{apiData.credit.copyright}</ReactMarkdown>
                  </div>
                </div>

                <div className="flex justify-center mt-5">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-lg font-medium text-white bg-rainbow rounded-full"
                    onClick={closeModal}
                    ref={closeBtnRef}
                  >
                    ปิดหน้าต่าง
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        )}
      </Dialog>
    </Transition>
  );
}
