import React, { useContext } from "react";
import Swiper from 'react-id-swiper';
import MainButton from '../components/MainButton';
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiContext from "./../context/ApiContext";

export default function Random() {

  const { apiData } = useContext(ApiContext);
  const history = useHistory();

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  }

  const cardsId =
    apiData &&
    apiData.cards && apiData.cards.map((item) => {
      return item["id"];
    });

  const listRandom = cardsId && shuffle(cardsId);

  // Card Swiper Options

  const cardSwiperParams = {
    effect: "coverflow",
    rebuildOnUpdate: true,
    grabCursor: true,
    slidesPerView: 5,
    spaceBetween: 140,
    mousewheel: true,
    loop: true,
    coverflowEffect: {
      rotate: 24,
      stretch: 20,
      depth: 0,
      modifier: 1,
      slideShadows: true,
    },
    breakpoints: {
      1600: {
        slidesPerView: 5,
        spaceBetween: 140,
        coverflowEffect: {
          rotate: 20,
          stretch: 20,
          depth: 60,
          modifier: 1,
        },
      },
      940: {
        slidesPerView: 5,
        spaceBetween: 50,
        coverflowEffect: {
          rotate: 20,
          stretch: 0,
          depth: 80,
          modifier: 1,
        },
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
        coverflowEffect: {
          rotate: 40,
          stretch: 0,
          depth: 80,
          modifier: 1,
        },
      },
      0: {
        slidesPerView: 3,
        spaceBetween: -12,
        coverflowEffect: {
          rotate: 30,
          stretch: 0,
          depth: 120,
          modifier: 1.2,
        },
      },
    },
    pagination: {
      el: ".swiper-pagination",
    },
  };

  // Single Card Component

  const CardSingle = ({ number }) => {
    return (
      <>
        <div
          className={`swiper-slide flex card-rainbow my-6 mb-10 md:my-12 lg:mt-12 lg:mb-16 rounded-xl overflow-hidden shadow-xl`}
        >
          <div
            className="card-wrapper border-white border-8 sm:border-12 lg:border-16 flex flex-1"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/loading?card=${number}`);
            }}
          >
            <div className="card-wrapper-inner rounded-lg">
              <div className="card-inner flex flex-1 rounded-md justify-center p-5 xl:py-32 lg:py-24 md:py-28 sm:py-24 py-16">
                <div className="card-checked flex items-center bg-gray-900 text-white p-3 px-4 rounded-full">
                  <FontAwesomeIcon
                    className="text-xl md:text-2xl"
                    icon={faCheck}
                  />
                  <span className="text-lg ml-2 mt-0.5 hidden xl:block">
                    เลือกใบนี้
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  return (
    <div className="view">
      <div className="w-full th-serif">
        <div className="px-3">
          <h1 className="text-pink-700 text-4xl md:text-5xl mb-6 font-bold">
            อธิษฐาน
          </h1>
          <p className="text-gray-700 text-base sm:text-xl lg:text-2xl">
            หายใจอย่างอ่อนโยน แล้วเลือกไพ่รุ้งมา 1 ใบ
          </p>
        </div>
        <div className="py-3">
          <Swiper {...cardSwiperParams}>
            {listRandom &&
              listRandom.map((num, idx) => {
                return <CardSingle number={num} key={idx} />;
              })}
          </Swiper>
        </div>
        <div>
          <MainButton
            btnStyle="secondary"
            icon={faArrowLeft}
            iconPos="left"
            title="กลับ"
            back
          />
        </div>
      </div>
    </div>
  );
}
