import React from "react";
import Intro from '../components/Intro';
import { faArrowRight, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import MainButton from '../components/MainButton';

export default function Welcome() {

  return (
    <div className="view">
      <div className="container mx-auto th-serif">
        <div>
          <Intro className="mb-12" />
          <MainButton
            btnStyle="primary"
            icon={faArrowRight}
            iconPos="right"
            title="ต่อไป"
            link="/random"
          />
        </div>
        <div className="mt-12">
          <MainButton
            icon={faLayerGroup}
            iconPos="left"
            btnStyle="secondary"
            title="ดูไพ่ทั้งหมด"
            link="/all-cards"
          />
        </div>
      </div>
    </div>
  );
}
