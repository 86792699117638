import React, { useState, useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Welcome from "./pages/Welcome";
import Random from "./pages/Random";
import Waiting from "./pages/Waiting";
import Result from "./pages/Result";
import AllCards from "./pages/AllCards";
import ApiContext from "./context/ApiContext";

function App() {

  const baseUrl = "https://strapi.mitmit.cc";

  const [apiData, setApiData] = useState({
    baseUrl: baseUrl
  });

  useEffect(() => {
    const fetchApi = async (path, store) => {
      await fetch(`${baseUrl}${path}`)
        .then((response) => response.json())
        .then((data) => {
          setApiData((prev) => {
            return {
              ...prev,
              [store]: data,
            };
          });
        });
    };

    fetchApi("/rainbow-cards", "cards");
    fetchApi("/credit", "credit");
  }, [setApiData]);

  return (
    <ApiContext.Provider value={{ apiData, setApiData }}>
      <div className="app">
        {/* <Counter /> */}
        <Router>
          <Route
            render={({ location }) => (
              <TransitionGroup className="switch-wrapper">
                <CSSTransition
                  onEnter={() => document.body.classList.add('overflow-hidden')}
                  onExited={() => document.body.classList.remove('overflow-hidden')}
                  key={location.key}
                  classNames="fade"
                  timeout={500}
                >
                  <div className="switch-absolute">
                    <Switch location={location}>
                      <Route path="/random" component={Random} />
                      <Route path="/loading" component={Waiting} />
                      <Route path="/result" component={Result} />
                      <Route path="/all-cards" component={AllCards} />
                      <Route path="/" component={Welcome} />
                    </Switch>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            )}
          />
        </Router>
      </div>
    </ApiContext.Provider>
  );
}

export default App;
