import React, { useEffect } from 'react';
import Loading from '../components/Loading';
import { useHistory } from "react-router-dom";
import queryString from "query-string";

export default function Waiting({location}) {

  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      const params = queryString.parse(location.search);
      history.replace({
        pathname: "/result",
        search: `?card=${params.card}`,
        state: { isActive: true },
      });
    }, 3000);
    return () => clearTimeout(timer);
  }, [history, location]);

  return (
    <div className="view">
      <Loading />
      <div className="text-gray-700 loading-dots mt-8 th-serif text-xl md:text-3xl">
        <p>
          ไพ่รุ้งของคุณคือ<span></span>
        </p>
      </div>
    </div>
  );
}
