import React, { useContext, useState } from "react";
import ApiContext from "./../context/ApiContext";
import transImg from "./../images/transparent.png";
import Spinner from "../components/Spinner";
import MainButton from "../components/MainButton";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function AllCards() {

  const { apiData } = useContext(ApiContext);

  const Card = ({ imgUrl, width, title }) => {
    const [loaded, setLoaded] = useState(false);
    return (
      <div className="flex items-center" style={{ perspective: "1000px" }}>
        <div className="relative mx-auto max-w-xs mb-0 md:mb-4 bg-white bg-opacity-50 rounded-2xl">
          {/* Placeholder */}
          {!loaded && (
            <div className="flex items-center justify-center">
              <Spinner className="absolute z-10" />
              <img
                src={transImg}
                style={{ width: width, height: "auto" }}
                alt=""
              />
            </div>
          )}

          {/* Card Result */}
          <img
            src={imgUrl}
            className={`rounded-2xl shadow-lg anim-scale-up ${
              loaded ? "block" : "hidden"
            }`}
            alt={`ไพ่รุ้ง - ${title}`}
            onLoad={() => setLoaded(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="view">
      <div className="container mx-auto th-serif">
        <div className="flex flex-col md:flex-row items-center justify-between px-6 mb-6">
          <h1 className="text-pink-700 text-3xl md:text-4xl font-bold mb-4 md:mb-0">
            ไพ่รุ้งทั้งหมด
          </h1>
          <MainButton
            btnStyle="secondary"
            title="กลับหน้าแรก"
            icon={faArrowLeft}
            iconPos="left"
            link="/"
          />
        </div>
        {apiData && apiData.cards ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-8 px-6">
            {apiData.cards.map((item, index) => {
              const baseUrl = apiData.baseUrl;
              return (
                <div key={index}>
                  <Card
                    imgUrl={baseUrl + item["Image"].formats.medium.url}
                    width={item["Image"].formats.medium.width}
                    title={item["Title"]}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <Spinner className="my-32" />
        )}
      </div>
    </div>
  );
}
